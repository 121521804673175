import hiringProcessHandler from "@/services/HiringProcess";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadHiringProcesses({commit, dispatch}, payload) {
        const {data} = await hiringProcessHandler.all(payload);

        commit('SET_HIRING_PROCESSES', data);
    }
}
