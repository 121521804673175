<template>
  <header>
    <b-container>
      <b-row>
        <b-col cols="12" class="text-center mt-5 mb-5">
          <router-link to="/">
            <b-img alt="Rabbi IT Firm Logo" width="220"  :src="require('@/assets/logo.png')"/>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
export default {
  name: "AppHeader"
}
</script>

<style scoped>

</style>