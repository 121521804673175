import Api from './Api';

const all = (payload) => {
    const path = '/front-hiring-step';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

export default {
    all
};