import jobHandler from "@/services/Job";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadJobs({commit, dispatch}, payload) {
        const {data} = await jobHandler.getAllJobs(payload);
        // eslint-disable-next-line

        commit('SET_JOBS', data);
    },
    // eslint-disable-next-line no-unused-vars
    async getJobById({commit, dispatch}, payload) {
        const {data} = await jobHandler.getJobId(payload);
        // eslint-disable-next-line
        commit('SET_JOB_BY_ID', data);
    }
}
