import locationHandler from "@/services/Location";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadLocations({commit, dispatch}, payload) {
        const {data} = await locationHandler.all(payload);

        commit('SET_LOCATIONS', data);
    }
}
