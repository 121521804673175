import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

import joinUs from "@/store/joinUs";
import privacy from "@/store/privacy";
import about from "@/store/about";
import job from "@/store/job";
import department from "@/store/department";
import location from "@/store/location";
import hiringProcess from "@/store/hiringProcess";
import jobApplication from "@/store/jobApplication";

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    joinUs,
    privacy,
    about,
    job,
    department,
    location,
    hiringProcess,
    jobApplication
  }
})
