import axios from "axios";
let basePath = process.env.VUE_APP_API_BASE_URL;

let Api = axios.create({
    baseURL: basePath,
})

Api.defaults.withCredentials = true;

Api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    // check if it's a server error
    if (!error.response) {
        //notify.warn('Network/Server error');
        console.log('**Network/Server error**');
        return Promise.reject(error);
    }

    // all the other error responses
    switch (error.response.status) {
        case 400:
            console.log(error.response.status, error.message);
            //notify.warn('Nothing to display', 'Data Not Found');
            break;

        case 401: // authentication error, logout the user
            //notify.warn('Please login again', 'Session Expired');
            console.log(error.response.status, error.message);
            localStorage.removeItem("token");
            localStorage.removeItem("auth");
            window.location.href = '/login';
            break;

        default:
            console.log(error.response.status, error.message);
        //notify.error('Server Error');
    }

    return Promise.reject(error);
});

const getAuthorizationHeader = () => {
    const token = localStorage.getItem('token');
    const header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    };
    if (token && token) {
        header['Authorization'] = `Bearer ${token}`;
    }
    return header;
};

const getRequest = async function (payload) {
    return Api({
        url: payload.endpoint,
        method: 'GET',
        headers: getAuthorizationHeader()
    });
};

const postRequest = async function (payload) {
    let data = payload.data;
    //let headers = getAuthorizationHeader();
    if(data && data.file) {
        //headers['Content-Type'] = 'multipart/form-data';
        let formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        })

        data = formData;

        return Api({
            url: payload.endpoint,
            method: payload.method || 'POST',
            headers: {'Content-Type': 'multipart/form-data'},
            data: data
        });
    }

    return Api({
        url: payload.endpoint,
        method: payload.method || 'POST',
        headers: getAuthorizationHeader(),
        data: data
    });
};

const deleteRequest = async function (payload) {
    return Api({
        url: payload.endpoint,
        method: 'DELETE',
        headers: getAuthorizationHeader()
    });
};

export default {
    getRequest,
    postRequest,
    deleteRequest
};
