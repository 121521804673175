<template>
<div class="home-footer">
  <b-nav align="center">
    <b-nav-item href="https://rabbiitfirm.com/">Home</b-nav-item>
    <b-nav-item href="https://rabbiitfirm.com/guest-posting">Services</b-nav-item>
    <b-nav-item href="https://rabbiitfirm.com/contact">Contact Us</b-nav-item>
  </b-nav>

</div>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>

</style>