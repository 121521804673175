<template>
<footer>
  <div class="app-footer">
    <b-nav align="left">
      <b-nav-item href="https://rabbiitfirm.com/">Rabbi IT Firm</b-nav-item>
      <b-nav-item to="/privacy-policy">
        Privacy Policy
      </b-nav-item>
      <b-nav-item to="/about-us">
        About Us
      </b-nav-item>
    </b-nav>
  </div>
</footer>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>

</style>