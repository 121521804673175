import departmentHandler from "@/services/Department";

export default {
    // eslint-disable-next-line no-unused-vars
    async loadDepartments({commit, dispatch}, payload) {
        const {data} = await departmentHandler.all(payload);

        commit('SET_DEPARTMENTS', data);
    }
}
