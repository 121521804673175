import Api from './Api';

const store = (payload) => {
    return Api.postRequest({
        endpoint: '/job-application/store',
        data: payload
    });
};

export default {
    store
};