<template>
  <div>
    <router-view name="header"/>

    <router-view/>

    <router-view name="homefooter"/>
    <router-view name="footer"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Rabbi IT Firm',
    titleTemplate: 'Jobs at %s | %s Careers',
    meta: [
      { name: 'description', content: 'Learn more about working at $s and discover our latest career opportunities' },
      { name: 'robots', content: 'index, follow' },
      { name: 'googlebot', content: 'index, follow' }
    ]
  }
}
</script>

<style lang="scss">
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}*/
</style>
