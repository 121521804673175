import AppHeader from "../layouts/AppHeader";
import AppFooter from "../layouts/AppFooter";
import HomeFooter from "@/layouts/HomeFooter";
const routes = [
    {
        path: '/',
        name: 'Home',
        components: {
            header: AppHeader,
            default: () => import('../views/HomeView'),
            homefooter: HomeFooter,
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/jobs/:id',
        name: 'JobDetails',
        components: {
            header: AppHeader,
            default: () => import('../views/JobDetailsView'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        components: {
            header: AppHeader,
            default: () => import('../views/PrivacyPolicy'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        components: {
            header: AppHeader,
            default: () => import('../views/AboutView'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/application/:jobId',
        name: 'Application',
        components: {
            header: AppHeader,
            default: () => import('../views/ApplicationView'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
];

export default routes;