import Api from './Api';

const getAllJobs = (payload) => {
    const path = '/front-jobs';
    const endpoint = payload ? `${path + '?' + Object.keys(payload).map(key => key + '=' + payload[key]).join('&')}` : path;

    return Api.getRequest({
        endpoint
    });
};

const getJobId = (payload) => {
    const path = `/front-job/${payload.id}`;

    return Api.getRequest({
        endpoint: path
    });
};

export default {
    getAllJobs,
    getJobId
};